import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import InvestmentPage from './investmentPage';
import SavingsInterestCalculator from './savingsInterest';
import ValuesPage from './valuesPage';
import InvestmentCalculator from './investmentCalculator';

const Home = () => (
  <div className="content-container">
    <p className="intro">Hi, this is my web 1.0 site.</p>
    <div className="links">
      <div className="link-item">
        <a href="https://guuup.com">/guuup</a>
        <p className="link-description">Handy Nutrition Data</p>
      </div>
      <div className="link-item">
        <Link to="/investments">/investments</Link>
        <p className="link-description">Handy Investment Cheatsheet</p>
      </div>
      <div className="link-item">
        <Link to="/investments-calculator">/investmentsCalculator</Link>
        <p className="link-description">Investment Calculator</p>
      </div>
      <div className="link-item">
        <Link to="/savings-interest-calculator">/savingsInterest</Link>
        <p className="link-description">Savings Interest Calculator</p>
      </div>
    </div>
  </div>
);

const App = () => {
  return (
    <Router>
      <div id="app-container">
        <div id="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/investments" element={<InvestmentPage />} />
            <Route path="/savings-interest-calculator" element={<SavingsInterestCalculator />} />
            <Route path="/values" element={<ValuesPage />} />
            <Route path="/investments-calculator" element={<InvestmentCalculator />} />
          </Routes>
        </div>
        <footer id="footer">
          <hr />
          Best viewed in Netscape Navigator
        </footer>
        <div className="netscape-body">
          <div className="netscape-button">
            <div className="netscape-logo"></div>
            <div className="netscape-text">
              <h1>NETSCAPE</h1>
              <p>Now!</p>
            </div>
            <h4 className="ribbon">3.0</h4>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
