import React, { useState } from 'react';

const calculateIndusind = (amount) => {
  if (amount <= 100000) return amount * 0.03;
  if (amount <= 500000) return amount * 0.05;
  if (amount <= 1000000) return amount * 0.06;
  if (amount <= 50000000) return amount * 0.0675;
  return amount * 0.0675;
};

const calculateIDFC = (amount) => {
  if (amount <= 500000) return amount * 0.03;
  if (amount <= 50000000) return 500000 * 0.03 + (amount - 500000) * 0.0725;
  if (amount <= 100000000) return 500000 * 0.03 + 49500000 * 0.0725 + (amount - 50000000) * 0.05;
  if (amount <= 200000000) return 500000 * 0.03 + 49500000 * 0.0725 + 50000000 * 0.05 + (amount - 100000000) * 0.045;
  return 500000 * 0.03 + 49500000 * 0.0725 + 50000000 * 0.05 + 100000000 * 0.045 + (amount - 200000000) * 0.035;
};

const calculateICICI_HDFC = (amount) => {
  return amount < 5000000 ? amount * 0.03 : amount * 0.035;
};

const calculateSBI = (amount) => {
  return amount < 100000000 ? amount * 0.027 : amount * 0.03;
};

const bankInfo = {
  Indusind: "Uses flat rates: 3% up to ₹1 Lakh, 5% up to ₹5 Lakhs, 6% up to ₹10 Lakhs, 6.75% up to ₹5 Crores, 6.75% above ₹5 Crores.",
  IDFC: "Uses stepped slabs: 3% up to ₹5 Lakhs, 7.25% from ₹5 Lakhs to ₹50 Cr, 5% from ₹50 Cr to ₹100 Cr, 4.5% from ₹100 Cr to ₹200 Cr, 3.5% above ₹200 Cr.",
  ICICI_HDFC: "Uses flat rates: 3% below ₹50 Lakhs, 3.5% above ₹50 Lakhs.",
  SBI: "Uses flat rates: 2.7% below ₹10 Cr, 3% above ₹10 Cr."
};

const SavingsInterestCalculator = () => {
  const [inputAmount, setInputAmount] = useState('');
  const [calculatedAmount, setCalculatedAmount] = useState(null);
  const [results, setResults] = useState({});

  const handleCalculate = () => {
    const amountValue = parseFloat(inputAmount);
    if (isNaN(amountValue) || amountValue <= 0) {
      alert('Please enter a valid positive number');
      return;
    }

    setCalculatedAmount(amountValue);

    const calculatedResults = {
      Indusind: calculateIndusind(amountValue),
      IDFC: calculateIDFC(amountValue),
      ICICI_HDFC: calculateICICI_HDFC(amountValue),
      SBI: calculateSBI(amountValue)
    };

    setResults(calculatedResults);
  };

  return (
    <div style={{ padding: '1rem', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Savings Interest Calculator</h1>
      <p style={{ fontSize: '0.875rem', marginBottom: '1rem', fontStyle: 'italic' }}>
        Information is based on the best available data as of 24th September 2024.
      </p>
      <div style={{ marginBottom: '1rem' }}>
        <input
          type="number"
          value={inputAmount}
          onChange={(e) => setInputAmount(e.target.value)}
          placeholder="Enter amount"
          style={{ width: '100%', padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <button 
        onClick={handleCalculate} 
        style={{ 
          marginBottom: '1rem', 
          padding: '0.5rem 1rem', 
          backgroundColor: '#0066cc', 
          color: 'white', 
          border: 'none', 
          borderRadius: '4px', 
          cursor: 'pointer' 
        }}
      >
        Calculate
      </button>
      {calculatedAmount !== null && (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '1rem' }}>
          {Object.entries(results).map(([bank, interest]) => (
            <div key={bank} style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '1rem' }}>
              <h2 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>{bank.replace('_', ' / ')}</h2>
              <p style={{ fontSize: '1rem' }}>Interest: ₹{interest.toFixed(2)}</p>
              <p style={{ fontSize: '0.875rem' }}>Rate: {(interest / calculatedAmount * 100).toFixed(2)}%</p>
              <p style={{ fontSize: '0.75rem', marginTop: '0.5rem', color: '#666' }}>{bankInfo[bank]}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavingsInterestCalculator;